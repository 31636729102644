import styled from 'styled-components'

export const StyledSuccess = styled.div`
  margin-top: 5rem;
  text-align: center;

  p {
    margin-bottom: 3rem;
  }
`
